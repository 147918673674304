export default {
  // Global styles apply to _all_ inputs with matching section keys
  global: {
    fieldset: '',
    form: 'space-y-4',
    help: 'text-xs text-gray-500',
    inner: 'formkit-disabled:bg-gray-200 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
    input: 'appearance-none bg-transparent focus:outline-none focus:ring-0 focus:shadow-none',
    label: 'block mb-2 font-bold text-sm',
    legend: 'font-bold text-sm',
    loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
    message: 'text-red-500 mb-1 text-xs',
    messages: 'list-none p-0 mt-1 mb-0',
    outer: 'mb-4 formkit-disabled:opacity-50',
    prefixIcon: 'w-10 flex self-stretch grow-0 shrink-0 text-slate-500 bg-transparent border-r border-slate-400 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon: 'w-7 pr-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto'
  },

  // Family styles apply to all inputs that share a common family
  'family:box': {
    decorator: 'block relative h-5 w-5 mr-2 rounded-lg bg-white ring-1 ring-gray-400 peer-checked:ring-brand-primary text-transparent peer-checked:text-brand-primary',
    decoratorIcon: 'flex p-[3px] w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
    help: 'mb-2 mt-1.5',
    input: 'fixed left-[999em] w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
    label: '$reset text-sm text-gray-700 mt-1 select-none',
    wrapper: 'flex items-center mb-1',
  },
  'family:button': {
    input: '$reset inline-flex items-center border-transparent rounded-md text-sm py-2 px-4 text-white bg-brand-primary shadow hover:bg-brand-secondary hover:text-yellow-800 focus:ring-2 focus:ring-brand-primary/75 focus:ring-offset-2 active:shadow-none font-semibold disabled:bg-slate-400 disabled:!text-white disabled:cursor-not-allowed',
    wrapper: 'mb-1',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch',
  },
  'family:dropdown': {
    dropdownWrapper:
      'my-2 w-full shadow-lg rounded formkit-dropdown-wrapper [&::-webkit-scrollbar]:hidden ',
    emptyMessageInner:
      'flex items-center justify-center text-sm p-2 text-center w-full text-slate-500 [&>span]:mr-3 [&>span]:ml-0',
    inner:
      'max-w-md relative flex ring-1 ring-slate-400 focus-within:ring-brand-primary focus-within:ring-2 rounded mb-2 formkit-disabled:focus-within:ring-slate-400 formkit-disabled:focus-within:ring-1 [&>span:first-child]:focus-within:text-brand-primary',
    input: 'w-full px-3 py-2',
    listbox: 'bg-white shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem:
      'pl-7 relative hover:bg-slate-300 data-[is-active="true"]:bg-slate-300 aria-selected:bg-brand-primary aria-selected:text-white data-[is-active="true"]:aria-selected:bg-brand-primary data-[is-active="true"]:aria-selected:bg-brand-primary',
    loaderIcon: 'ml-auto',
    loadMoreInner:
      'flex items-center justify-center text-sm p-2 text-center w-full text-brand-primary formkit-loading:text-slate-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
    option: 'p-2.5',
    optionLoading: 'pl-2.5 text-slate-400',
    placeholder: 'p-2.5 text-slate-400',
    selector:
      'bg-white rounded flex w-full justify-between items-center min-h-[2.625em] [&u] cursor-default',
    selection: 'flex w-full',
    selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
    selectIcon:
      'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em] cursor-pointer',
  },
  'family:text': {
    inner: 'flex items-center max-w-md bg-white ring-1 ring-gray-400 focus-within:ring-brand-primary focus-within:ring-2 [&>label:first-child]:focus-within:text-brand-primary rounded-lg mb-1',
    input: 'w-full px-3 py-2 border-none text-base text-gray-700 placeholder-gray-400',
  },

  // Specific styles apply only to a given input type
  'color': {
    wrapper: 'flex flex-col space-y-2',
    inner: 'rounded-lg p-1 bg-white flex w-full !max-w-[4rem]',
    input: '$reset !w-full h-12 appearance-none cursor-pointer border border-slate-300 rounded-lg p-2 bg-white [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-slate-200 [&::-webkit-color-swatch]:rounded-md',
    suffixIcon: 'min-w-[2.5em] pr-0 pl-0 m-auto'
  },
  file: {
    fileItem: 'flex items-center text-gray-800 mb-1 last:mb-0',
    fileItemIcon: 'w-4 mr-2 shrink-0',
    fileList: 'shrink grow peer px-3 py-2 formkit-multiple:data-[has-multiple="true"]:mb-6',
    fileName: 'break-all grow text-ellipsis',
    fileRemove: 'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-brand-primary peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
    fileRemoveIcon: 'block text-base w-3 relative z-[2]',
    inner: 'relative max-w-md cursor-pointer formkit-multiple:[&>button]:absolute',
    input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0 z-[2]',
    noFiles: 'flex w-full items-center px-3 py-2 text-gray-400',
    noFilesIcon: 'w-4 mr-2'
  },
  radio: {
    decorator: 'rounded-full',
    decoratorIcon: 'w-5 p-[5px]'
  },
  range: {
    inner: '$reset flex items-center max-w-md',
    input: '$reset w-full mb-1 h-2 p-0 rounded-full',
    prefixIcon: '$reset w-4 mr-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon: '$reset w-4 ml-1 flex self-stretch grow-0 shrink-0 [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto'
  },
  select: {
    inner: 'flex relative items-center rounded mb-1 ring-1 ring-gray-400 focus-within:ring-brand-primary focus-within:ring-2 [&>span:first-child]:focus-within:text-brand-primary overflow-hidden',
    input: 'w-full pl-3 pr-8 py-2 border-none text-base text-gray-700 placeholder-gray-400 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-400 formkit-multiple:data-[placeholder="true"]:text-inherit bg-white',
    // selectIcon: 'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700'
  },
  textarea: {
    inner: 'flex rounded-lg mb-1 ring-1 ring-gray-400 focus-within:ring-brand-primary [&>label:first-child]:focus-within:text-brand-primary',
    input: 'block w-full h-32 px-3 py-3 border-none text-base text-gray-700 placeholder-gray-400 focus:shadow-outline bg-white',
  },

  // PRO input styles
  autocomplete: {
    closeIcon: 'block grow-0 shrink-0 w-3 mr-3.5',
    inner: '[&>div>[data-value]]:absolute [&>div>[data-value]]:mb-0 rounded-lg',
    option: 'grow text-ellipsis',
    input: 'border-0 rounded-lg',
    selection: 'static flex items-center left-0 top-0 right-0 bottom-0 mt-0 mb-2 rounded-lg bg-gray-100',
  },
  mask: {
    inner: 'flex items-center max-w-md ring-1 ring-gray-400 focus-within:ring-brand-primary focus-within:ring-2 [&>label:first-child]:focus-within:text-brand-primary rounded mb-1',
    input: 'w-full px-3 py-2 border-none text-base text-gray-700 placeholder-gray-400',
  },
  dropdown: {
    tagsWrapper: 'max-w-[calc(100%_-_35px)]',
    tags: 'flex items-center flex-wrap gap-1 mx-2 my-1.5',
    tag: 'flex items-center rounded-full bg-gray-200 text-xs text-black py-1 px-2.5 cursor-default',
    tagLabel: 'px-1',
    selectionsWrapper: 'flex w-[calc(100%_-_35px)] overflow-hidden',
    selections: 'inline-flex items-center px-2.5',
    selectionsItem: 'whitespace-nowrap mr-1 last:mr-0',
    truncationCount:
      'flex items-center whitespace-nowrap justify-center rounded text-white bg-gray-500 font-bold text-xs px-1 py-0.5',
    removeSelection: 'block w-2.5 my-1 cursor-pointer',
  },
  rating: {
    inner: 'relative flex items-center w-[8em] formkit-disabled:bg-transparent',
    itemsWrapper: 'w-full',
    onItems: 'text-yellow-400',
    onItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none',
    offItems: 'text-gray-500',
    offItemWrapper: '[&>*]:w-full [&>svg]:h-auto [&>svg]:max-w-none [&>svg]:max-h-none'
  },
  repeater: {
    content: 'grow flex flex-col align-center',
    controlLabel: 'absolute opacity-0 pointer-events-none',
    controls: 'flex items-center items-center gap-1 @sm:gap-2 rounded-lg border border-slate-300 px-2 py-1 text-slate-500 mt-[3px] bg-white h-max',
    downControl: 'hover:text-brand-primary disabled:hover:text-inherit disabled:opacity-25',
    fieldset: '',
    help: 'mb-2 mt-1.5',
    items: 'space-y-3',
    item: 'flex w-full gap-2 @container',
    moveDownIcon: 'block w-3',
    moveUpIcon: 'block w-3',
    removeControl: 'hover:text-brand-primary disabled:hover:text-inherit disabled:opacity-25',
    removeIcon: 'block w-4',
    upControl: 'hover:text-brand-primary disabled:hover:text-inherit disabled:opacity-25',
    addButton: 'mt-4',
    up: 'flex',
    down: 'flex',
    remove: 'flex',
  },
  taglist: {
    input: 'px-1 py-1 w-[0%] grow',
    removeSelection: 'w-2.5 mx-1 self-center text-black leading-none',
    tag: 'flex items-center my-1 p-1 bg-gray-200 text-xs rounded-full',
    tagWrapper: 'mr-1 focus:outline-none focus:text-white [&>div]:focus:bg-brand-primary [&>div>button]:focus:text-white',
    tagLabel: 'pl-2 pr-1',
    tags: 'flex items-center flex-wrap w-full px-3 py-2 border-none text-base text-gray-700',
  },
  toggle: {
    altLabel: 'block w-full mb-1 font-bold text-sm',
    inner: '$reset inline-block mr-2',
    input: 'peer fixed left-[999em] opacity-0 pointer-events-none',
    innerLabel: 'text-[10px] font-bold absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
    thumb: 'relative left-0 aspect-square rounded-full transition-all w-5 bg-gray-100',
    track: 'p-0.5 min-w-[3em] relative rounded-full transition-all bg-gray-400 peer-checked:bg-brand-primary peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
    valueLabel: 'font-bold text-sm',
    wrapper: 'flex flex-wrap items-center mb-1'
  },
  mediaInput: {
    help: 'mt-2',
  }
}
