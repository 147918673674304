<template>
  <div v-if="showReloadPrompt" class="pwa-toast" role="alert">
    <BrandLogo class="mb-1" :text-white="true" :hide-text-on-mobile="false" />

    <button class="absolute top-2 right-2 text-slate-500">
      <IconTablerX @click="close" />
    </button>

    <div class="message mt-2 mb-3">
      <UiLoader v-if="updating" label="Updating your Mixo experience. Hold tight..." />
      <span v-else
        >A new version of the app is available. Click on the reload button to
        update.</span
      >
    </div>

    <UiButton v-if="!updating" theme="brand-primary" size="sm" @click="onUpdate">
      Reload
    </UiButton>
  </div>
</template>

<script setup>
import { useRegisterSW } from "virtual:pwa-register/vue";
import { track } from "@/lib/tracking";
import { eagerComputed } from "@vueuse/core";
const { needRefresh, updateServiceWorker } = useRegisterSW();
const route = useRoute();

// Data
const updating = ref(false);
const showReloadPrompt = eagerComputed(() => {
  // If we're on the preview route then don't show the reload prompt
  if (route?.path === "/sites/preview") {
    return false;
  }

  return needRefresh.value;
});

// Methods
async function close() {
  needRefresh.value = false;
}

async function onUpdate() {
  updating.value = true;
  await updateServiceWorker();
  close();
  updating.value = false;

  // Get the frontend version from the environment variables and trigger mixpanel event
  const frontendVersion = import.meta.env.VITE_GITHUB_SHA || import.meta.env.VITE_APP_ENV;
  track("App Updated", { frontendVersion });

  // Reload the page after the service worker has been updated but check with the user first
  if (confirm("The app has been updated. Reload now?")) {
    location.reload();
  }
}
</script>

<style>
.pwa-toast {
  @apply bg-slate-700 shadow-lg border border-gray-300 rounded-lg p-4 fixed top-0 right-0 m-4 text-white z-50;
}
</style>
